import axios from 'axios'
import { SERVER_HOST } from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });


// 美食种类
 export const reqGetFoodsCategoryListPage = params => { return instance.get(`${SERVER_HOST}/admin/foodsCategory/page`, { params: params }) };
 export const reqAddFoodsCategory = params => { return instance.post(`${SERVER_HOST}/admin/foodsCategory/add`, params) };
 export const reqEditFoodsCategory = params => { return instance.post(`${SERVER_HOST}/admin/foodsCategory/edit`, params) };
 export const reqDeleteFoodsCategory = params => { return instance.delete(`${SERVER_HOST}/admin/foodsCategory/delete/` + params.id, params) };
 export const reqBatchDeleteFoodsCategory = params => { return instance.post(`${SERVER_HOST}/admin/foodsCategory/batchDelete/`, params) };
 export const reqGetFoodsCategoryOne = params => { return instance.get(`${SERVER_HOST}/admin/foodsCategory/one`, { params: params }) };
 export const getMerchantNames = params => { return instance.get(`${SERVER_HOST}/admin/merchant/selectMerchantNames`) };